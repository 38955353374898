import Vue from 'vue'
import Vuex from 'vuex'
import { aboutUs } from '../api/index'
import { titleOne, titleTwo, titleThree, description1, description2, description3 } from '../api/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aboutUsList: [],
    metaInfo: {
      title: "",
      description: ""
    }
  },
  getters: {
  },
  mutations: {
    CHANGE_META_INFO(state, metaInfo) {
      state.metaInfo = metaInfo;
    }
  },
  actions: {
    getList() {
      aboutUs().then((res) => {
        console.log(this);
        this.state.aboutUsList = res.data
        localStorage.setItem("aboutUsList", JSON.stringify(res.data));
        console.log(res, 'vuex')
      }).catch()
    },
    async titleOne() {
      try {
        const res = await titleOne()
        console.log('titleOne', res)
        this.state.metaInfo.title = res.data
        console.log(this.state.metaInfo.title,'this.state.metaInfo.title');
        localStorage.setItem("hometitle", res.data);
        // 保存数据
      } catch (err) {
        console.log('titleOne', err)
      }
    },
    async titleTwo() {
      try {
        const res = await titleTwo()
        console.log('titleOne', res)
        this.state.metaInfo.title = res.data
        localStorage.setItem("aboutTitle", res.data);
        // 保存数据
      } catch (err) {
        console.log('titleOne', err)
      }
    },
    async titleThree() {
      try {
        const res = await titleThree()
        console.log('titleThree', res)
        this.state.metaInfo.title = res.data
        localStorage.setItem("price", res.data);
        // 保存数据
      } catch (err) {
        console.log('titleOne', err)
      }
    },
    async description1() {
      try {
        const res = await description1()
        console.log('description1', res)
        this.state.metaInfo.description = res.data
        console.log(this.state.metaInfo.description);
        localStorage.setItem("description1", res.data);
        // 保存数据
      } catch (err) {
        console.log('description1', err)
      }
    },
    async description2() {
      try {
        const res = await description2()
        console.log('description2', res)
        this.state.metaInfo.description = res.data
        localStorage.setItem("description2", res.data);
        // 保存数据
      } catch (err) {
        console.log('description2', err)
      }
    },
    async description3() {
      try {
        const res = await description3()
        console.log('description3', res)
        this.state.metaInfo.description = res.data
        localStorage.setItem("description3", res.data);
        // 保存数据
      } catch (err) {
        console.log('description3', err)
      }
    },
    async prlogo() {
      try {
        const res = await prlogo();
        // this.logoimg = res.data;
        localStorage.setItem("logoimg", res.data);
        console.log("prlogo", res);
        // 保存数据
      } catch (err) {
        console.log("prlogo", err);
      }
    },
  }
})
