<template>
  <div id="app">
    <!-- <Top></Top> -->
    <router-view />
    <bottomVue></bottomVue>
  </div>
</template>
<script>
// import Top from "./components/Top.vue";
import bottomVue from "./components/Bottom.vue";
export default {
  components: {
    bottomVue,
    // Top,
  },
  data() {
    return {};
  },
  methods: {
    gobtn() {
      this.$router.push({ name: "about" });
    },
  },
  beforeCreate() {
  }
};
</script>
<style lang="scss">
html,
body {
  overflow: -Scroll;
  overflow-x: hidden;
}
img {
  border-radius: 4px;
}
#app {
  font-family: Source Han Sans CN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
  color: #ffffff !important;
}
.active {
  text-decoration: none;
  position: relative;
  color: #f7e2b4 !important;
}
.activedetail {
  text-decoration: none;
  position: relative;
  color: #f7e2b4 !important;
}

.bluecolor {
  background-color: #25304c !important;
  color: #ffffff !important;
}
.mg15 {
  margin-top: 15px;
}
.Relative {
  position: relative;
}

@media (min-width: 577px) {
  .swiper-pagination {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
    z-index: 98;
  }
  .swiper-pagination1 {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
    z-index: 98;
  }
  .alltxt {
    position: relative;
    .Engtxt {
      font-family: AlimamaShuHeiTi-Bold;
      font-size: 38px;
      color: #e5e5e5;
      letter-spacing: 0.5px;
      text-align: center;
    }
    .chinesetxt {
      position: absolute;
      top: 30px;
      font-family: Source Han Sans CN;
      font-size: 30px;
      color: #203670;
      letter-spacing: 0.9px;
      text-align: center;
      font-weight: 700;
    }
  }
  .cel {
    margin-top: 32px;
    margin: 0 auto;
    width: 80% !important;
  }
  .active:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%);
    width: 50px;
    height: 2px;
    background-color: #f7e2b4;
  }
  .size22 {
    font-family: Source Han Sans CN;
    font-size: 22px;
    line-height: 39px;
    color: #000000;
    text-align: center;
  }
  .Wsize22 {
    font-family: Source Han Sans CN;
    font-size: 22px;
    line-height: 39px;
    color: #ffffff;
    text-align: left;
  }
  .img2 {
    height: 66px;
  }
  .pt25 {
    padding-top: 25px;
    font-family: Source Han Sans CN;
    font-size: 24px;
    color: #000000;
  }
}
@media (max-width: 576px) {
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    z-index: 98;
  }
  .swiper-pagination1 {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    z-index: 98;
  }
  .alltxt {
    position: relative;
    .Engtxt {
      font-family: AlimamaShuHeiTi-Bold;
      font-size: 16px;
      color: #e5e5e5;
      letter-spacing: 0.5px;
      text-align: center;
      font-weight: 700;
    }
    .chinesetxt {
      position: absolute;
      top: 12px;
      font-family: Source Han Sans CN;
      font-size: 12px;
      color: #203670;
      letter-spacing: 0.9px;
      text-align: center;
      font-weight: 700;
    }
  }
  .cel {
    margin-top: 12px;
    margin: 0 auto;
    width: 80% !important;
  }
  .active:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%);
    width: 30px;
    height: 2px;
    background-color: #f7e2b4;
  }
  .size22 {
    font-family: Source Han Sans CN;
    font-size: 12px;
    line-height: 19px;
    color: #000000;
    text-align: center;
  }
  .Wsize22 {
    font-family: Source Han Sans CN;
    font-size: 12px;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
  }
  .img2 {
    height: 36px;
  }
  .pt25 {
    padding-top: 15px;
    font-family: Source Han Sans CN;
    font-size: 12px;
    color: #000000;
  }
  .navtxt {
    margin-left: 15px;
    font-size: 16px;
    color: #a52626;
    font-weight: 400;
  }
  .img1 {
    width: 99px;
    height: 34px;
  }
}
</style>
