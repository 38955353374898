import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import BootstrapVue from "bootstrap-vue"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
// 引入bootstrap样式
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'//这里注意具体看使用的版本是否需要引入样式，以及具体位置。
Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(ElementUI)
Vue.config.productionTip = false


new Vue({
	router,
	store,
	metaInfo() {
		return {
			title: this.$store.state.metaInfo.title,
			meta: [{
				name: "description",
				content: this.$store.state.metaInfo.description
			}
			]
		}
	},
	render: h => h(App),  // 预渲染
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
	// 预渲染触发器
}).$mount('#app')

