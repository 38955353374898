<template>
  <div>
    <Top :cuidx="2" v-if="barShow == '1'"></Top>
    <div class="Detail" id="content">
      <div class="detailbg1">
        <swiper :options="swiperOption">
          <!--也可以换成图片-->
          <swiper-slide v-for="(item, index) in swiperOption1" :key="index">
            <img
              id="img_"
              :src="item.image"
              alt=""
              class="detailbg1 img-responsive"
            />
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
      <!-- <div class="detailbg1">
      <img src="@/assets/7.jpg" alt="" />
    </div> -->
      <!-- 面包屑 -->
      <!-- <div class="nevs">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb" style="margin-top: 12px">
              <li class="breadcrumb-item"><a href="/Project"></a></li>
              <li class="breadcrumb-item"><a href="/Project">项目动态</a></li>
              <li class="breadcrumb-item activedetail" aria-current="page">
                {{ details.name }}
              </li>
            </ol>
          </nav>
        </div>
      </div> -->
      <div class="row">
        <div class="maptitle">{{ details.name }}</div>
      </div>
      <div class="main">
        <pre>
          <div class="container detailmain " v-html="details.brief_content"></div>
        </pre>
      </div>
      <div class="container btnbottom" v-if="show">
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              style="font-size: 0.6rem"
              class="btn bluecolor"
              @click="previous"
            >
              上一篇：{{ previousName }}
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              style="font-size: 0.6rem"
              class="btn bluecolor"
              @click="next"
            >
              下一篇：{{ nextName }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Top from "../components/Top.vue";
import { aboutDetail, getBar, showTwoList } from "../api/index.js";
export default {
  components: {
    Top,
  },
  data() {
    return {
      barShow: 0, // 控制bar
      aboutusList: [],
      index: 1,
      id: "",
      show: true, // 上下条的显示
      details: {},
      swiperOption1: [1, 2],
      swiperOption: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    previousName() {
      return this.index == 0
        ? "没有上一条了"
        : this.aboutusList[this.index - 1].name;
    },
    nextName() {
      return this.index == this.aboutusList.length - 1
        ? "没有下一条了"
        : this.aboutusList[this.index + 1].name;
    },
  },
  methods: {
    godetail(id) {
      // + ".html";
      let detailId = id;
      this.$router.push({ name: "detail", params: { detailId: detailId } });
      // this.$router.push({
      //   name: "detail",
      //   params: {
      //     id,
      //   },
      // });
    },
    init() {
      this.aboutusList.forEach((item, idx) => {
        if (item.id == this.id) {
          this.index = idx;
          console.log("当前index", this.index);
        }
      });
    },
    previous() {
      if (this.previousName == "没有上一条了") return;
      console.log(this.aboutusList);
      this.id = this.aboutusList[this.index - 1].id;
      this.godetail(this.aboutusList[this.index - 1].id);
      this.getList(this.aboutusList[this.index - 1].id);
    },
    next() {
      if (this.nextName == "没有下一条了") return;
      this.id = this.aboutusList[this.index + 1].id;
      this.getList(this.aboutusList[this.index + 1].id);
      this.godetail(this.aboutusList[this.index + 1].id);
    },
    getList(id) {
      aboutDetail({ id: id })
        .then((res) => {
          this.details = res.data;
          console.log(res);
          this.init();
          this.returnTop();
        })
        .catch();
    },
    // 返回顶部
    returnTop() {
      content.scrollIntoView();
    },
  },
  created() {
    var isWechat = (function () {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    })();
    if (isWechat) {
      this.show = false;
      console.log("在小程序");
    } else {
      console.log("不在小程序");
    }
    // .indexOf('.')
    const idx = this.$route.params.detailId;
    // console.log("id", this.$route.params.detailId.substring(0,idx));
    // .substring(0,idx);
    this.id = this.$route.params.detailId;
    this.aboutusList = JSON.parse(localStorage.getItem("aboutUsList"));
    this.getList(this.$route.params.detailId);
    // 导航栏状态
    getBar()
      .then((res) => {
        this.barShow = res.data;
        console.log("导航栏状态", res.data);
      })
      .catch();
    showTwoList()
      .then((res) => {
        console.log(res);
        this.swiperOption1 = res.data;
      })
      .catch();
  },
};
</script>
<style lang="scss" scoped>
.nevs {
  padding: 2px 0;
  display: flex;
  align-items: center;
  background-color: #14234b;
}
.main {
  // width: 1120px;
  margin: 0 auto;
  padding: 24px 0;
  box-sizing: border-box;
  // min-height: 80vh;
  text-align: left;
  font-family: Source Han Sans CN;
  font-size: 24px;
  background: #f5f7fa;
  color: #000000;
  line-height: 39px;
  font-weight: 400;
}
.detailmain {
  // text-align: center;
  white-space: pre-wrap;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  // background-color: #ffffff;
}
.btnbottom {
  padding-top: 24px;
}
@media (min-width: 577px) {
  .btn {
    font-size: 0.6rem;
  }
  .main {
    width: 1120px;
    min-height: 50vh;
  }
  ::v-deep .swiper-pagination-bullet {
    width: 24px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
  }
  ::v-deep .swiper-pagination-bullet-active {
    width: 24px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
    background: #023892;
  }
  .maptitle {
    padding: 20px 0;
    font-family: Source Han Sans CN;
    font-size: 30px;
    color: #203670;
    font-weight: 700;
    letter-spacing: 0.9px;
  }
  .navtxt {
    margin-left: 80px;
    font-size: 20px;
    font-weight: 400;
  }
  .img1 {
    width: 169px;
    height: 44px;
  }

  .detailbg1 {
    position: relative;
    // height: 800px;
    height: auto;
    img {
      width: 100%;
      height: auto;
      // height: 100%;
    }
  }
}
@media (max-width: 576px) {
  .main {
    width: 350px;
    min-height: 50vh;
  }
  .btn {
    font-size: 0.6rem;
  }
  ::v-deep .swiper-pagination-bullet {
    width: 18px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
  }
  ::v-deep .swiper-pagination-bullet-active {
    width: 18px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
    background: #023892;
  }
  .maptitle {
    padding: 10px 0;
    font-family: Source Han Sans CN;
    font-size: 20px;
    color: #203670;
    font-weight: 700;
    letter-spacing: 0.9px;
  }
  .detailbg1 {
    position: relative;
    width: 100%;
    // height: 200px;
    height: auto;
    img {
      width: 100%;
      // height: 100%;
      height: auto;
    }
  }
  .navtxt {
    margin-left: 20px;
    font-size: 16px;
    color: #a52626;
    font-weight: 400;
  }
  .img1 {
    width: 99px;
    height: 34px;
  }
}
</style>
