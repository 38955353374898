<template>
  <div class="about">
    <Top :cuidx="2" v-if="!show"></Top>
    <div class="aboutbg1">
      <swiper :options="swiperOption">
        <!--也可以换成图片-->
        <swiper-slide v-for="(item, index) in swiperOption1" :key="index">
          <img
            id="img_"
            :src="item.image"
            alt=""
            class="aboutbg1 img-responsive"
          />
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
    <div class="bg2">
      <div class="row alltxt">
        <div class="Engtxt">———— About us ————</div>
        <div class="chinesetxt">关于我们</div>
      </div>
    </div>
    <div class="bg3">
      <div class="container">
        <div
          class="bg3box"
          v-for="(item, index) in aboutUsList"
          :key="index"
          @click="godetail(item.id)"
        >
          <div class="row">
            <div class="col-xl-8 col-12">
              <div class="text1">{{ item.name }}</div>
              <div class="text2">
                {{ item.infor }}
              </div>
              <div class="text3">{{ item.createtime }}</div>
            </div>
            <div class="col-xl-4 col-12">
              <img class="imgbg3 img-responsive" :src="item.image" alt="" />
            </div>
          </div>
        </div>
        <div
          v-if="!loading"
          class="container btn d-flex justify-content-center align-items-center text-center"
          @click="loadMore"
        >
          {{ moreShow ? "没有更多了" : "查看更多"
          }}<img src="@/assets/xiajiantou.png" alt="" v-if="!moreShow" />
        </div>
        <Loading v-if="loading"></Loading>
      </div>
    </div>
  </div>
</template>
<script>
import { showTwoList, aboutUs } from "../api/index";
import Loading from "../components/Loading.vue";
import Top from "../components/Top.vue";
export default {
  components: {
    Loading,
    Top,
  },
  data() {
    return {
      loading: false, // 加载状态
      swiperOption1: [1, 2],
      swiperOption: {
        loop: false,
        speed: 2000,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      list: "", //条数
      page: 1, // 页数
      aboutUsList: [], // 关于我们数组
      total: 1, // 一共多少条
      moreShow: false,
      show: 0,
    };
  },
  methods: {
    godetail(id) {
      // + ".html";
      let detailId = id;
      this.$router.push({ name: "detail", params: { detailId: detailId } });
      // this.$router.push({
      //   name: "detail",
      //   params: {
      //     id,
      //   },
      // });
    },
    // 查看更多
    loadMore() {
      if (this.moreShow) return;
      this.loading = true;
      this.page = this.page + 1;
      aboutUs({ page: this.page })
        .then((res) => {
          this.total = res.data.total;
          this.page == 2
            ? (this.aboutUsList = res.data.data)
            : (this.aboutUsList = this.aboutUsList.concat(res.data.data));
          if (this.total == this.aboutUsList.length) {
            this.moreShow = true;
          }
          console.log("res", res);
          this.loading = false;
        })
        .catch();
    },
  },
  beforeCreate() {
    this.$store.dispatch("titleThree");
    this.$store.dispatch("description3");
  },
  created() {
    // console.log(this.$route.params);
    // if (this.$route.params.show != "0") {
    //   console.log(this.$route.params.show);
    //   this.show = this.$route.params.show;
    // }
    this.$store.dispatch("getList");
    showTwoList()
      .then((res) => {
        console.log(res);
        this.swiperOption1 = res.data;
      })
      .catch();
    aboutUs({ page: this.page })
      .then((res) => {
        this.aboutUsList = res.data;
        console.log("res", res);
        // if (this.total == this.aboutUsList.length) {
        //   this.moreShow = true;
        // }
      })
      .catch();
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 577px) {
  .swiper-pagination {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
    z-index: 98;
  }

  ::v-deep .swiper-pagination-bullet {
    width: 24px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
  }
  ::v-deep .swiper-pagination-bullet-active {
    width: 24px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
    background: #023892;
  }
  .aboutbg1 {
    position: relative;
    width: 100%;
    // height: 800px;
    height: auto;
  }

  .bg2 {
    padding: 24px 0;
  }
  .bg3 {
    padding: 24px 0;
    background: #f5f7fa;
    .bg3box {
      margin-top: 24px;
      text-align: left;
      box-sizing: border-box;
      padding: 16px 36px;
      background: #ffffff;
      .imgbg3 {
        width: 100%;
        // height: 100%;
        height: auto;
      }
      .text1 {
        font-weight: 600;
        font-family: PingFangSC-Semibold;
        font-size: 27px;
        color: #203670;
      }
      .text2 {
        margin-top: 7px;
        font-family: Source Han Sans CN;
        font-size: 24px;
        color: #000000;
        line-height: 39px;
        font-weight: 400;
      }
      .text3 {
        margin-top: 18px;
        font-family: Source Han Sans CN;
        font-size: 22px;
        color: #989898;
      }
    }
  }
  .btn {
    margin-top: 36px;
    width: 200px;
    height: 54px;
    border: 1px solid rgba(126, 126, 126, 1);
    border-radius: 1px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
@media (max-width: 576px) {
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    z-index: 98;
  }

  ::v-deep .swiper-pagination-bullet {
    width: 18px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
  }
  ::v-deep .swiper-pagination-bullet-active {
    width: 18px !important;
    height: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-block;
    background: #023892;
  }
  .aboutbg1 {
    position: relative;
    width: 100%;
    // height: 200px;
    height: auto;
    img {
      width: 100%;
      // height: 200px;
      height: auto;
    }
  }

  .bg2 {
    padding: 24px 0;
  }
  .bg3 {
    padding-bottom: 24px;
    padding-top: 1px;
    background: #f5f7fa;
    .bg3box {
      margin-top: 16px;
      text-align: left;
      box-sizing: border-box;
      padding: 12px 12px;
      background: #ffffff;
      .imgbg3 {
        margin-top: 4px;
        width: 100%;
        // height: 100%;
        height: auto;
      }
      .text1 {
        font-weight: 600;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #203670;
      }
      .text2 {
        margin-top: 4px;
        font-family: Source Han Sans CN;
        font-size: 12px;
        color: #000000;
        line-height: 19px;
        font-weight: 400;
      }
      .text3 {
        margin-top: 4px;
        font-family: Source Han Sans CN;
        font-size: 8px;
        color: #989898;
      }
    }
  }
  .btn {
    margin-top: 16px;
    width: 120px;
    height: 36px;
    font-size: 12px;
    border: 1px solid rgba(126, 126, 126, 1);
    border-radius: 1px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
</style>
