<template>
  <div class="bglast">
    <div class="container">
      <div class="last row">
        <div class="col-xl-9 col-12 order-xl-first order-last last-txt">
          <div class="row">
            <div class="col-xl-4 col-12 last2">
              客服电话：
              {{ phoneObj }}
              <!-- <span
                v-for="(item, index) in mobileList"
                :key="index"
                style="margin-right: 12px"
                >{{ item.name }}</span
              > -->
            </div>
            <div class="col-xl-4 col-12 last2">步长集团：{{ grouptit }}</div>
            <div class="col-xl-4 col-12 last2">
              联系地址：北京市通州区榆景东路1号院步长国际
            </div>
          </div>
          <div class="last1 last2 row">
            <div class="col-xl-4 col-12">
              {{ onetitle }}
            </div>
            <div class="col-xl col-12">
              {{ twotitle }}
            </div>
          </div>
          <div class="last1 last2 row">
            <div class="col-xl-4 col-12"><a href="https://beian.miit.gov.cn" target="_blank">{{ threetitle }}</a></div>
            <div class="col-xl col-12">{{ fourtitle }}</div>
          </div>
        </div>
        <div class="col-xl-3 col-12 order-first">
          <div class="row">
            <div class="col-6 text-center">
              <img class="imglogo" :src="account" alt="" />
              <div class="last2">步长国际公众号</div>
            </div>
            <div class="col-6 text-center">
              <img class="imglogo" :src="mini" alt="" />
              <div class="last2">步长国际小程序</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  account,
  mini,
  one,
  two,
  three,
  four,
  phone,
  group,
} from "../api/index";
export default {
  name: "Bootom",
  data() {
    return {
      account: "", // 公众号
      mini: "", // 小程序
      onetitle: "",
      twotitle: "",
      threetitle: "",
      fourtitle: "",
      mobileList: "",
      phoneObj: "",
      grouptit: "",
    };
  },
  methods: {
    async group() {
      try {
        const res = await group();
        this.grouptit = res.data;
        console.log("group", res);
        // 保存数据
      } catch (err) {
        console.log("group", err);
      }
    },
    // 页尾
    async one() {
      try {
        const res = await one();
        this.onetitle = res.data;
        console.log("one", res);
        // 保存数据
      } catch (err) {
        console.log("one", err);
      }
    },
    async two() {
      try {
        const res = await two();
        this.twotitle = res.data;
        console.log("two", res);
        // 保存数据
      } catch (err) {
        console.log("two", err);
      }
    },
    async three() {
      try {
        const res = await three();
        this.threetitle = res.data;
        console.log("three", res);
        // 保存数据
      } catch (err) {
        console.log("three", err);
      }
    },
    async four() {
      try {
        const res = await four();
        this.fourtitle = res.data;
        console.log("four", res);
        // 保存数据
      } catch (err) {
        console.log("four", err);
      }
    },
    async phone() {
      try {
        const res = await phone();
        this.phoneObj = res.data;
        console.log("phone", res);
        // 保存数据
      } catch (err) {
        console.log("phone", err);
      }
    },
  },
  created() {
    this.group();
    this.one();
    this.two();
    this.three();
    this.four();
    this.phone();
    // 获取公众号照片
    account()
      .then((res) => {
        this.account = res.data;
      })
      .catch();
    // 获取小程序图片
    mini()
      .then((res) => {
        this.mini = res.data;
      })
      .catch();
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 577px) {
  .bglast {
    margin-top: 20px;
    padding: 20px 0;
    box-sizing: border-box;
    opacity: 0.9;
    background-image: url("@/assets/19.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .last {
    text-align: left;
    font-family: Source Han Sans CN;
    font-size: 12px;
    color: #e8e8e8;
  }
  .last2 {
    font-size: 12px;
  }
  .last1 {
    margin-top: 15px;
  }
  .imglogo {
    margin-bottom: 12px;
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 576px) {
  .bglast {
    margin-top: 10px;
    padding: 10px 0;
    opacity: 0.9;
    background-image: url("@/assets/19.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .last {
    text-align: left;
    font-family: Source Han Sans CN;
    font-size: 6px;
    color: #e8e8e8;
  }
  .last2 {
    line-height: 22px;
    font-size: 12px;
    margin-top: 4px;
  }
  .last-txt {
    margin-top: 12px;
  }
  .imglogo {
    width: 56px;
    height: 55px;
  }
}
</style>
