import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import Project from '../views/Project.vue'
import Detail from '../views/detail.vue'
// 先导入vuex中的方法
import { mapMutations } from 'vuex'
// const title = { ...mapMutations(['hometitle']) }
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      metaInfo: {
        title: '步长国际',
        description: '步长国际'
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      metaInfo: {
        title: '步长国际',
        description: '步长国际'
      }
    }
  },
  {
    path: '/Project',
    name: 'Project',
    component: Project,
    meta: {
      metaInfo: {
        title: '步长国际',
        description: '步长国际'
      }
    }
  },
  {
    path: '/detail/:detailId',
    name: 'detail',
    component: Detail,
    meta: {
      metaInfo:
      {
        title: '步长国际',
        description: '步长国际'
      }
    }
  },
]
const scrollBehavior = function (to, from, savedPosition) {
  // savedPosition 会在你使用浏览器前进或后退按钮时候生效
  // 这个跟你使用 router.go() 或 router.back() 效果一致
  // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
  if (savedPosition) {
    return savedPosition
  } else {
    // 如果不是通过上述行为切换组件，就会让页面回到顶部
    return { x: 0, y: 0 }
  }
}
const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  base: process.env.BASE_URL,
  routes
})
// 第一种
// router.beforeEach((to, from, next) => {
//   if (to.meta.content) {
//     let head = document.getElementsByTagName('head');
//     let meta = document.createElement('meta');
//     document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
//     meta.content = to.meta.content;
//     head[0].appendChild(meta)
//     console.log('description1', localStorage.getItem("description1"));
//     // console.log('to.meta.content;', to.meta.content,meta.content,head[0].appendChild(meta));
//   }
//   if (to.meta.title) {
//     document.title = to.meta.title;

//     // document.description = to.meta.content.description
//     // console.log(to.meta.content.description);
//     // console.log(document.description);
//   }
//   next()
// });
// 第二种
// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo) {
    // console.log(store.commit("metaModule/CHANGE_META_INFO", to.meta.metaInfo));
    store.commit("CHANGE_META_INFO", to.meta.metaInfo)
  }
  if (to.path) {
    console.log(to.path,'路由');
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next()
})

export default router
