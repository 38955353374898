<template>
  <div class="row navTop align-items-center" style="margin: 0" v-if="show">
    <div class="container">
      <ul
        class="nav align-items-center text-center container"
        :class="tabShow ? 'between' : 'between'"
      >
        <li class="nav-item" @click="jump(0)" v-if="logoimg">
          <img class="img1" :src="logoimg" alt="" />
        </li>
        <li class="nav-item" v-else>
         <div class="img1"></div>
        </li>
        <div
          class="nav justify-content-center align-items-center text-center hide"
          v-if="tabShow"
        >
          <li class="nav-item navtxt">
            <router-link to="/" active-class="active" exact>首页</router-link>
          </li>
          <li class="nav-item navtxt">
            <router-link to="/about" active-class="active"
              >租赁信息</router-link
            >
          </li>
          <li class="nav-item navtxt" v-if="cuidx != 2">
            <router-link to="/Project" active-class="active"
              >项目动态</router-link
            >
          </li>
          <li class="nav-item navtxt" v-if="cuidx == 2">
            <router-link to="/Project" class="active">项目动态</router-link>
          </li>
        </div>
        <div @click="openflag = !openflag" v-if="!tabShow">
          <img src="@/assets/ce.png" alt="" class="ce" />
        </div>
      </ul>
    </div>
    <!-- animate__fadeInDown  animate__slideInDown -->
    <ul class="pop animate__animated animate__fadeInDown" v-if="openflag">
      <div class="text-center">
        <li
          @click="jump(index)"
          class="cnav-item navtxt"
          :style="index == cindex ? 'color:#f7d08b' : ''"
          v-for="(item, index) in navList"
          :key="index"
        >
          {{ item }}
          <!-- <router-link to="/" exact>首页</router-link> -->
        </li>
        <!-- <li class="cnav-item navtxt">
          <router-link to="/about">租赁信息</router-link>
        </li>
        <li class="cnav-item navtxt">
          <router-link to="/Project">项目动态</router-link>
        </li> -->
      </div>
    </ul>
  </div>
</template>

<script>
import { prlogo } from "@/api/";

export default {
  name: "Top",
  props: {
    cuidx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: true,
      screenWidth: null, //屏幕尺寸
      tabShow: true,
      openflag: false,
      navList: ["首页", "租赁信息", "项目动态"],
      cindex: 0,
      logoimg: "",
    };
  },
  mounted() {
    this.prlogo();
    var isWechat = (function () {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    })();
    if (isWechat) {
      this.show = false;
      console.log("在小程序");
    } else {
      console.log("不在小程序");
    }
    var ua = navigator.userAgent.toLowerCase();
    console.log(ua);
    console.log(ua.match(/MicroMessenger/i));
    // if (
    //   ua.match(/MicroMessenger/i) === "micromessenger" ||
    //   ua.indexOf("micromessenger") !== -1
    // ) {
    //   wx.miniProgram.getEnv((res) => {
    //     if (res.miniprogram) {
    //       console.log("在小程序");
    //       this.show = false;
    //     } else {
    //       console.log("不在小程序");
    //     }
    //   });
    // } else {
    //   console.log("在浏览器");
    // }
    this.cindex = this.cuidx;
    console.log(this.cuidx, "选中当前");
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    async prlogo() {
      try {
        const res = await prlogo();
        this.logoimg = res.data;
        console.log("prlogo", res);
        // 保存数据
      } catch (err) {
        console.log("prlogo", err);
      }
    },
    jump(index) {
      this.cindex = index;
      switch (index) {
        case 0:
          this.$router.push({
            name: "home",
          });
          break;
        case 1:
          this.$router.push({
            name: "about",
          });
          break;
        case 2:
          this.$router.push({
            name: "Project",
          });
          break;
      }
    },
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val <= 577) {
          this.tabShow = false;
          // console.log("屏幕宽度小于577px");
        } else {
          this.tabShow = true;
          // console.log("屏幕宽度大于577px");
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
li {
  list-style: none;
}
.between {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .navTop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 63px;
    background: rgba($color: #14234d, $alpha: 0.9);
    // font-family: Source Han Sans CN;
    font-family: Noto Sans SC;
    margin: 0;
    opacity: 0.9;

    .nav {
      width: 100%;
    }
    .pop {
      position: absolute;
      top: 63px;
      left: 0;
      background: rgba($color: #14234d, $alpha: 0.9);
      // margin-top: 36px;
      z-index: -1;
      .cnav-item {
        display: flex;
        justify-content: center;
        align-content: center;
        height: 44px;
        color: #fff;
        font-weight: bold;
        // border-bottom: 1px solid #fff;
      }
    }
  }
  .navtxt {
    margin-left: 20px;
    font-size: 16px;
    font-family: Noto Sans SC;
    color: #a52626;
    font-weight: 400;
    opacity: 1 !important;
  }
  .img1 {
    width: 99px;
    height: 34px;
    opacity: 1 !important;
  }
  .ce {
    width: 34px;
    height: 34px;
  }
}
//顶部
@media (min-width: 577px) {
  .navTop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100%;
    height: 93px;
    background: rgba($color: #14234d, $alpha: 0.9);
    // font-family: Source Han Sans CN;
    font-family: Noto Sans SC;
    margin: 0;
    opacity: 0.9;
  }
  .navtxt {
    margin-left: 80px;
    font-size: 20px;
    font-weight: 400;
    font-family: Noto Sans SC;
    opacity: 1 !important;
  }
  .img1 {
    width: 169px;
    height: 44px;
    opacity: 1 !important;
  }
}
.nav-item {
  cursor: pointer;
}
</style>
