import http from './http'
// 获取首页轮播图 /api/index/slideShow
export const swiperList = params => http.get('/api/index/slideShow', params)

// 获取项目动态轮播图 /api/index/showTwo
export const showTwoList = params => http.get('/api/index/showTwo', params)

// 获取租赁信息轮播图 /api/index/showThree
export const showThreeList = params => http.get('/api/index/showThree', params)

// 获取项目简介图片及介绍 /api/index/intro
export const introList = params => http.get('/api/index/intro', params)

// 获取配套设施文字介绍 /api/index/facilityTwo
export const facilityTwo = params => http.get('/api/index/facilityTwo', params)

// 获取配套设施图片及介绍 /api/index/facility
export const facility = params => http.get('/api/index/facility', params)

// 获取步长国际介绍 /api/index/declare
export const declare = params => http.get('/api/index/declare', params)

// 获取租赁服务图片及介绍 /api/index/lease
export const lease = params => http.get('/api/index/lease', params)

// 获取租赁楼信息 /api/index/floor
export const floor = params => http.get('/api/index/floor', params)

// 获取租赁信息图片 /api/index/picture
export const picture = params => http.get('/api/index/picture', params)

// 获取关于我们介绍 /api/index/aboutUs
export const aboutUs = params => http.get('/api/index/aboutUs', params)

// 动态详情 /api/index/aboutDetail
export const aboutDetail = params => http.get('/api/index/aboutDetail', params)

// 动态详情导航栏状态 /api/index/bar
export const getBar = params => http.get('/api/index/bar', params)

// 获取项目简介 /api/basics/project
export const project = params => http.get('/api/basics/project', params)

// 获取配套设施 /api/basics/facility
export const basics = params => http.get('/api/basics/facility', params)

// 获取租赁服务 /api/basics/lease
export const basicsLease = params => http.get('/api/basics/lease', params)

// 获取租赁信息 /api/basics/message
export const message = params => http.get('/api/basics/message', params)

// 获取区域优势 /api/basics/region
export const region = params => http.get('/api/basics/region', params)

// 获取优质服务 /api/basics/serve
export const serve = params => http.get('/api/basics/serve', params)

// 获取租赁展示主图 /api/basics/picture
export const pictureImg = params => http.get('/api/basics/picture', params)

// 获取客服电话 /api/basics/phone
export const phone = params => http.get('/api/basics/phone', params)

// 获取公众号图片 /api/basics/account
export const account = params => http.get('/api/basics/account', params)

// 获取小程序图片 /api/basics/mini
export const mini = params => http.get('/api/basics/mini', params)

// 获取经纬度及地点名称 /api/basics/place
export const place = params => http.get('/api/basics/place', params)

// 获取手机号 /api/basics/mobile
export const mobile = params => http.get('/api/basics/mobile', params)

// 获取页尾设置一 /api/basics/one
export const one = params => http.get('/api/basics/one', params)

// 获取页尾设置二 /api/basics/two
export const two = params => http.get('/api/basics/two', params)

// 获取页尾设置三 /api/basics/three
export const three = params => http.get('/api/basics/three', params)

// 获取页尾设置四 /api/basics/four
export const four = params => http.get('/api/basics/four', params)

// 获取标题一 /api/basics/titleOne
export const titleOne = params => http.get('/api/basics/titleOne', params)

// 获取标题二 /api/basics/titleTwo
export const titleTwo = params => http.get('/api/basics/titleTwo', params)

// 获取标题三 /api/basics/titleThree
export const titleThree = params => http.get('/api/basics/titleThree', params)

// 获取logo /api/esbanner/prlogo
export const prlogo = params => http.get('/api/esbanner/prlogo', params)

//  获取标题描述1 /api/esbanner/description1
export const description1 = params => http.get('/api/esbanner/description1', params)

//  获取标题描述2 /api/esbanner/description2
export const description2 = params => http.get('/api/esbanner/description2', params)

//  获取标题描述3 /api/esbanner/description3
export const description3 = params => http.get('/api/esbanner/description3', params)

//  获取步长集团 /api/basics/group
export const group = params => http.get('/api/basics/group', params)