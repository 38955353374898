export default function loadBMap(ak) {
  // console.log(ak,);
  return new Promise(function (resolve, reject) {
    if (typeof window.BMap !== 'undefined') {
      resolve(window.BMap)
      return true
    }
    window.onBMapCallback = function () {
      resolve(window.BMap)
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      'https://api.map.baidu.com/api?v=3.0&ak=' + ak + '&callback=onBMapCallback'
    script.onerror = reject
    setTimeout(() => {
      document.head.appendChild(script)
    }, 1000)

  })
}
