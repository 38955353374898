<template>
  <div class="home">
    <Top></Top>
    <!-- 导航栏 -->
    <div class="bannera">
      <swiper :options="swiperOption">
        <!--也可以换成图片-->
        <swiper-slide
          v-for="(item, index) in swiperOption.swiperList"
          :key="index"
          class="switem"
        >
          <img
            @click="tolink(item)"
            id="img_"
            :src="item.image"
            alt=""
            class="bannera"
            v-if="swiperOption.swiperList.length"
          />
          <!-- <div :style="{background:'url('+item.image+')'}"></div> --> </swiper-slide
        >Introduction
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
    <!-- 第三部分 -->
    <div class="bg1">
      <div class="container">
        <div class="row alltxt">
          <div class="Engtxt">——— Project Introduction ———</div>
          <div class="chinesetxt">项目简介</div>
        </div>
        <div class="row pt25">
          <div class="cel distance">
            {{ picture }}
          </div>
        </div>
      </div>
      <div class="homebg pt25">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-12">
              <div
                class="Relative"
                style="overflow: hidden"
                v-for="(item, index) in introList.slice(0, 1)"
                :key="index"
              >
                <img
                  class="img3"
                  :src="item.image"
                  alt=""
                  @mouseenter="item.emergeShow = true"
                  @mouseleave="item.emergeShow = false"
                />
                <!-- animate__slideInUp -->
                <div
                  class="hovergroup animate__animated animate__slideInUp"
                  v-if="item.emergeShow"
                >
                  <div class="hovertxt1">{{ item.name }}</div>
                  <div class="hovertxt2">
                    {{ item.infor }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-12 d-flex flex-column justify-content-between"
            >
              <div
                class="Relative"
                style="overflow: hidden"
                v-for="(item, index) in introList.slice(1, 3)"
                :key="index"
              >
                <img
                  class="img4"
                  :src="item.image"
                  alt=""
                  @mouseenter="item.emergeShow = true"
                  @mouseleave="item.emergeShow = false"
                />
                <div
                  class="hovergroup animate__animated animate__slideInUp"
                  v-if="item.emergeShow"
                >
                  <div class="hovertxt1">{{ item.name }}</div>
                  <div class="hovertxt2">
                    {{ item.infor }}
                  </div>
                </div>
              </div>
              <!-- <div class="Relative">
                <img
                  class="img4 top10 img-responsive"
                  src="@/assets/3.jpg"
                  alt=""
                />
                <div class="hovergroup">
                  <div class="hovertxt1">步长国际 精心匠造 精工户型</div>
                  <div class="hovertxt2">
                    项目总建面5.9万平米，是由4栋35层围合式建筑组成
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第四部分 -->
    <div class="bg2">
      <div class="row alltxt">
        <div class="Engtxt">——— Supporting Facilities ———</div>
        <div class="chinesetxt">配套设施</div>
      </div>
      <div class="row pt25">
        <div class="cel">
          {{ basics }}
        </div>
      </div>
      <div class="container text-center ju">
        <div class="row">
          <div class="col teitem" v-for="(item, index) in facilityTwoList">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="pt25">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-4 col-12"
              v-for="(item, index) in facilityList"
              :key="index"
            >
              <div class="Relative" style="overflow: hidden">
                <img
                  class="img5"
                  :src="item.image"
                  alt=""
                  @mouseenter="item.emergeShow = true"
                  @mouseleave="item.emergeShow = false"
                />
                <div
                  class="hovergroup1 animate__animated animate__slideInUp"
                  v-if="item.emergeShow"
                >
                  <div class="hovertxt1">{{ item.name }}</div>
                  <div class="hovertxt2">{{ item.infor }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第五部分 -->
    <div class="bg3">
      <div class="container">
        <div class="bg3title">步长国际动态</div>
      </div>
      <div class="row line"></div>
      <div class="box5 container">
        <div class="row">
          <div class="col-xl-5 col-12">
            <img class="img6" :src="declaretion.image" alt="" />
          </div>
          <div class="col-xl-7 col-12">
            <div class="title3">{{ declaretion.name }}</div>
            <div class="Wsize22">
              {{ declaretion.infor }}
            </div>
            <div
              @click="toProject"
              class="container btn d-flex justify-content-center align-items-center text-center"
            >
              查看更多
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第六部分 -->
    <div class="bg4">
      <div class="row alltxt">
        <div class="Engtxt">———— Rental services ————</div>
        <div class="chinesetxt">租赁服务</div>
      </div>
      <div class="row pt25">
        <div class="container cel">
          {{ basicsLease }}
        </div>
      </div>
      <div class="pt25">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-4 col-12"
              v-for="(item, index) in leaseList"
              :key="index"
              @click="toabout"
            >
              <div class="card carditem">
                <img :src="item.image" class="card-img-top" alt="" />
                <div class="card-body" v-if="item.moreShow">
                  <div class="bgimg"><img src="../assets/bg.png" alt="" /></div>
                  <div class="texta">
                    {{ item.moreShow ? item.infor : item.infor.slice(0, 82) }}
                    <span
                      v-if="!item.moreShow"
                      @click="jump"
                      style="color: #2f7cfe"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第七部分 -->
    <div class="bg4">
      <div class="row">
        <div class="maptitle">区域优势</div>
      </div>
      <div class="row pt25">
        <div class="container cel">
          {{ region }}
        </div>
      </div>
      <div class="pt25">
        <div class="container">
          <div class="row">
            <div id="map-container" class="imgmap" />
            <!-- <img class="imgmap img-responsive" src="@/assets/map.png" alt="" /> -->
            <!-- <div @click="getLoad" style="cursor: pointer">立刻前往</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-ui";
import Top from "../components/Top.vue";

import {
  swiperList,
  introList,
  facility,
  declare,
  lease,
  project,
  basics,
  basicsLease,
  region,
  facilityTwo,
  place,
} from "../api/index.js";
import { mapMutations } from "vuex";
import loadBMap from "../api/loadBMap.js";
export default {
  components: {
    Top,
  },
  data() {
    return {
      form: {
        address: "", // 详细地址
        addrPoint: {
          // 详细地址经纬度
          lng: 0,
          lat: 0,
        },
      },
      map: "", // 地图实例
      mk: "", // Marker实例
      locationPoint: null,
      swiperOption: {
        loop: false,
        speed: 2000,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        swiperList: [], // 轮播图
      },
      introList: [], // 项目介绍
      facilityList: [], // 配套设施
      declaretion: {}, // 介绍
      leaseList: [], //租聘
      image: "https://img.bizhizu.com/2015/1231/20151231030245752.jpg",
      picture: "", // 项目介绍
      basics: "", // 配套设施
      basicsLease: "", // 租聘服务
      region: "", // 区域优势
      facilityTwoList: [],
      address: {}, // 经纬度
      myaddress: {}, // 当前位置
    };
  },
  beforeCreate() {
    this.$store.dispatch("titleOne");
    this.$store.dispatch("description1");
  },
  created() {
    // 轮播图
    swiperList()
      .then((res) => {
        this.swiperOption.swiperList = res.data;
        // console.log(res);
        // console.log("轮播图", this.swiperOption.swiperList);
      })
      .catch();
    // 获取项目介绍
    introList()
      .then((res) => {
        // console.log(res);
        res.data.forEach((it) => {
          it.emergeShow = false;
        });
        this.introList = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // 获取配套文字介绍
    facilityTwo()
      .then((res) => {
        this.facilityTwoList = res.data;
      })
      .catch();
    // 获取配套设施图片及介绍
    facility()
      .then((res) => {
        res.data.forEach((it) => {
          it.emergeShow = false;
        });
        this.facilityList = res.data;
        // console.log("facility", res);
      })
      .catch();
    // 获取步长国际介绍
    declare()
      .then((res) => {
        this.declaretion = res.data;
        // console.log("declaretion", res);
      })
      .catch();
    // 获取租聘服务介绍
    lease()
      .then((res) => {
        res.data.forEach((it) => {
          if (it.infor.length > 0) {
            it.moreShow = false;
          } else {
            it.moreShow = true;
          }
        });
        this.leaseList = res.data;
        // console.log(res.data[0].infor.length);
        // console.log("leaseList", res);
      })
      .catch();
    // 获取项目介绍
    project()
      .then((res) => {
        this.picture = res.data;
        // console.log("picture1", res);
      })
      .catch();
    // 获取配套设施
    basics()
      .then((res) => {
        this.basics = res.data;
        // console.log(res);
      })
      .catch();
    //获取租聘服务
    basicsLease()
      .then((res) => {
        this.basicsLease = res.data;
      })
      .catch();
    // 获取区域优势
    region().then((res) => {
      this.region = res.data;
    });
    // 获取经纬度
    // place().then((res) => {
    //   this.place = res.data;
    // });
  },
  // oW2UEhdth2tRbEE4FUpF9E5YVDCIPYih   本地
  async mounted() {
    await loadBMap("oW2UEhdth2tRbEE4FUpF9E5YVDCIPYih"); // 加载引入BMap
    this.place();
    // await this.initMap();
    // this.$nextTick(() => {
    //     this.initMap();
    //   })
    // gY3xMbPyYeVzu9zoO2GTKI9icrq1teGt    项目
  },
  methods: {
    toabout() {
      this.$router.push({ name: "about" });
    },
    // 获取当前位置
    getLoad() {
      var that = this;
      var geolocation = new BMap.Geolocation();
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(
        function (res) {
          if (res) {
            console.log("获取地址");
            console.log(res);
            // that.myaddress = res;
            that.snavigation(res);
          } else {
            alert("请检查您的浏览器是否禁用了获取位置");
            // ElMessage.error('请检查您的浏览器是否禁用了获取位置)
            console.log("默认地址");
            const Url = `https://uri.amap.com/navigation?from=${""},${""},${""}&to=${
              that.address.lng
            },${that.address.lat},${"步长国际"}&callnative=1`;
            window.location.href = Url;
            // that.snavigation(res);
          }
        },
        { enableHighAccuracy: false }
      ); //enableHighAccuracy：是否要求浏览器获取最佳效果，默认为false
    },
    // 导航
    snavigation(val) {
      let that = this;
      let myaddress = val;
      // let myLocation = JSON.parse(sessionStorage.getItem("ownChooseLocation"));
      console.log(that.address);
      if (that.address.lng && that.address.lat) {
        let Url = "";
        // switch (val) {
        //   case "amap":
        //     Url = `https://uri.amap.com/navigation?from=${myLocation.lng},${myLocation.lat},${myLocation.formattedAddress}&to=${that.cabinData.longitude},${that.cabinData.latitude},${that.cabinData.name}&callnative=1`;
        //     break;
        //   case "bmap":
        //     Url = `http://api.map.baidu.com/direction?origin=latlng:${myLocation.lat},${myLocation.lng}|name:${myLocation.formattedAddress}&destination=latlng:${that.cabinData.latitude},${that.cabinData.longitude}|name:${that.cabinData.name}&mode=driving&origin_region=${myLocation.city}&destination_region=${that.cabinData.cityName}&output=html&src=webapp.baidu.openAPIdemo`;
        //     break;
        // }
        // 百度地图
        // Url = `http://api.map.baidu.com/direction?origin=latlng:${
        //   myaddress.latitude
        // },${myaddress.longitude}|name:${
        //   myaddress.address.province+myaddress.address.district+myaddress.address.street
        // }&destination=latlng:${that.address.lat},${
        //   that.address.lng
        // }|name:${"北京市通州区榆景东路1号院"}&mode=driving&origin_region=${
        //   myaddress.province
        // }&destination_region=${
        //   myaddress.province
        // }&output=html&src=webapp.baidu.openAPIdemo`;
        // 高德地图
        Url = `https://uri.amap.com/navigation?from=${myaddress.longitude},${
          myaddress.latitude
        },${
          myaddress.address.province +
          myaddress.address.district +
          myaddress.address.street
        }&to=${that.address.lng},${
          that.address.lat
        },${"步长国际"}&callnative=1`;
        // window.location.href = Url;
        window.open(Url, '_blank')
      } else {
        this.$message({
          message: "请先确认您的起点和终点位置",
          type: "warning",
        });
      }
    },
    async place() {
      try {
        const res = await place();
        this.address = res.data;
        this.initMap();
        console.log("place", res);
        // 保存数据
      } catch (err) {
        console.log("place", err);
      }
    },
    jump() {
      this.$router.push({
        name: "about",
      });
    },
    tolink(item) {
      console.log(item);
      window.location.href = item.link;
    },
    // 跳转到动态
    toProject() {
      this.$router.push({
        name: "Project",
      });
    },
    // 初始化地图
    initMap() {
      var that = this;
      // 1、挂载地图
      // console.log("经纬度", that.address.lat, that.address.lng);
      this.map = new BMap.Map("map-container", { enableMapClick: false });
      var point = new BMap.Point(that.address.lng, that.address.lat);
      this.map.centerAndZoom(point, 17);
      // 信息窗口

      // 3、设置图像标注并绑定拖拽标注结束后事件
      this.mk = new BMap.Marker(point, { enableDragging: true });
      this.map.addOverlay(this.mk);
      this.mk.addEventListener("dragend", function (e) {
        that.getAddrByPoint(e.point);
      });
      var optsInfo = {
        // width: 25, // 信息窗口宽度
        // height: 20, // 信息窗口高度
        // title: "步长国际", // 信息窗口标题
      };
      // 自定义信息化窗口
      var infoWindow = new BMap.InfoWindow(
        ` <image style=' position: relative; top: 0;left: 0;width:600px;height:180px' src="${this.image}" alt="" />` +
          "<br/>" +
          `<input style='display:inline-block;width:100%; text-align:center ;height:120px;background: #fff;'>天津市西青区社会山南苑</input>`,
        optsInfo
      );
      // this.map.openInfoWindow(infoWindow, point); //开启信息窗口
      var infoWindow = new BMap.InfoWindow(
        `<span>步长国际</span>` +
          "<br/>" +
        `<span>北京市通州区榆景东路1号院</span>` +
          "<br/>" +
          `<input style=' position: absolute;
      bottom: 0;
      right: 0;color: #47acfa;border: none;' id="button" type="button" value="导航"></input>`,
        optsInfo
      ); // 创建信息窗口对象
      this.map.openInfoWindow(infoWindow, point); //开启信息窗口
      this.mk.addEventListener("click", function () {
        this.map.openInfoWindow(infoWindow, point); //开启信息窗口
      });
      setTimeout(() => {
        var btn = document.getElementById("button");
        btn.onclick = function () {
          that.getLoad();
        };
      }, 2000);
      // 4、添加（左上角）平移缩放控件
      this.map.addControl(
        new BMap.NavigationControl({
          anchor: BMAP_ANCHOR_TOP_LEFT,
          type: BMAP_NAVIGATION_CONTROL_SMALL,
        })
      );
      // BMAP_NAVIGATION_CONTROL_LARGE 表示显示完整的平移缩放控件。
      // BMAP_NAVIGATION_CONTROL_SMALL 表示显示小型的平移缩放控件。
      // BMAP_NAVIGATION_CONTROL_PAN 表示只显示控件的平移部分功能。
      // BMAP_NAVIGATION_CONTROL_ZOOM 表示只显示控件的缩放部分功能。

      // 5、添加（左下角）定位控件
      var geolocationControl = new BMap.GeolocationControl({
        anchor: BMAP_ANCHOR_BOTTOM_LEFT,
      });
      geolocationControl.addEventListener("locationSuccess", function (e) {
        that.getAddrByPoint(e.point);
      });
      geolocationControl.addEventListener("locationError", function (e) {
        alert(e.message);
      });
      this.map.addControl(geolocationControl);

      // 6、浏览器定位
      // this.geolocation();

      // 7、绑定点击地图任意点事件
      this.map.addEventListener("click", function (e) {
        // that.getAddrByPoint(e.point);
      });
    },
    // 获取两点间的距离
    // getDistancs(pointA, pointB) {
    //   return this.map.getDistance(pointA, pointB).toFixed(2);
    // },
    // 浏览器定位函数
    // geolocation() {
    //   var that = this;
    //   var geolocation = new BMap.Geolocation();
    //   geolocation.getCurrentPosition(
    //     function (res) {
    //       if (this.getStatus() == BMAP_STATUS_SUCCESS) {
    //         that.getAddrByPoint(res.point);
    //         that.locationPoint = res.point;
    //       } else {
    //         alert("failed" + this.getStatus());
    //         that.locationPoint = new BMap.Point(that.place.lng, that.place.lat);
    //       }
    //     },
    //     { enableHighAccuracy: true }
    //   );
    // },
    // 2、逆地址解析函数
    // getAddrByPoint(point) {
    //   var that = this;
    //   var geco = new BMap.Geocoder();
    //   geco.getLocation(point, function (res) {
    //     console.log(res);
    //     that.mk.setPosition(point);
    //     that.map.panTo(point);
    //     that.form.address = res.address;
    //     that.form.addrPoint = point;
    //   });
    // },
  },
};
</script>
<style>
.BMap_bubble_title {
  font-size: 12px !important;
}
.BMap_bubble_content {
  font-size: 12px !important;
}
@media (max-width: 576px) {
  /* .BMap_top {
    width: 145px !important;
  } */
  /* 隐藏四个边角 */
  /* 左 */
  /* .BMap_pop div:nth-child(1) {
    display: none;
  } */
  /* // 右 */
  /* .BMap_pop div:nth-child(3) {
    display: none;
  } */
  /* 下左 */
  /* .BMap_pop div:nth-child(5) {
    display: none;
  } */
  /* .BMap_pop div:nth-child(7) {
    display: none;
  } */
}
</style>
<style lang="scss" scoped>
.zidx {
  position: relative;
  z-index: 1000;
}
.home {
  .navTop {
    width: 100%;
    // height: 93px;
    background: #14234b;
    font-family: MicrosoftYaHei;
    margin: 0;
  }
  .w100 {
    width: 100%;
  }
  .bannera {
    position: relative;
  }
  // pc端
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 577px) {
    .swiper-pagination {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translate(-50%);
      z-index: 98;
    }
    ::v-deep .swiper-pagination-bullet {
      width: 24px !important;
      height: 8px;
      border-radius: 0;
      margin-right: 4px;
      display: inline-block;
    }
    ::v-deep .swiper-pagination-bullet-active {
      width: 24px !important;
      height: 8px;
      border-radius: 0;
      margin-right: 4px;
      display: inline-block;
      background: #023892;
    }

    .maptitle {
      font-family: Source Han Sans CN;
      font-size: 30px;
      color: #203670;
      font-weight: 700;
      letter-spacing: 0.9px;
    }
    .navtxt {
      font-size: 20px;
      color: #a52626;
      font-weight: 400;
    }
    .ju {
      margin-top: 38px;
    }
    .teitem {
      width: 398px;
      height: 50px;
      line-height: 50px;
      background: #f5f5f5;
      margin-right: 2px;
      color: #606060;
    }
    .img3 {
      width: 100%;
      // height: 440px;
      height: auto;
    }
    .img4 {
      width: 100%;
      // height: 210px;
      height: auto;
    }
    .img5 {
      margin-top: 10px;
      width: 100%;
      // height: 278px;
      height: auto;
    }
    .img6 {
      width: 100%;
      // height: 278px;
      height: auto;
    }
    .imgmap {
      height: 600px;
      width: 100%;
      // height: auto;
    }
    .imglogo {
      width: 56px;
      height: 55px;
    }

    .bg1 {
      padding: 60px 0;
      background-color: #f5f5f5;
    }
    .distance {
      margin-bottom: 16px;
    }
    .homebg {
      background-image: url("@/assets/advantageBg@1x.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg2 {
      padding: 24px 0;
    }
    .bg3 {
      text-align: left;
      height: 448px;
      background: #0d1a38;
      background-image: url("@/assets/17.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg4 {
      padding: 24px 0;
    }
    .title3 {
      margin-bottom: 3px;
      font-family: PingFangSC-Medium;
      font-size: 24px;
      font-weight: 600;
      color: #eeeeee;
    }
    .bg3title {
      position: relative;
      text-align: left;
      padding: 22px 0;
      font-family: Source Han Sans CN;
      font-size: 30px;
      color: #f7e2b4;
    }
    .bg3title:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 88px;
      left: 7%;
      transform: translate(-50%);
      width: 8%;
      height: 2px;
      background-color: #f7e2b4;
    }
    .line {
      margin-bottom: 37px;
      height: 1px;
      opacity: 0.21;
      border: 1px solid rgba(255, 248, 233, 1);
    }
    .btn {
      width: 184px;
      height: 54px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 1px;
      font-size: 24px;
      color: #f7e2b4;
      margin-top: 25px;
    }
    .bannera {
      // width: 100%;
      // height: 100vh;
      width: 100%;
      height: auto;
      overflow: hidden;
      .switem {
        width: 100%;
        height: auto;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          animation-name: scaleDraw; /*关键帧名称*/
          animation-timing-function: ease-in-out; /*动画的速度曲线*/
          animation-iteration-count: infinite; /*动画播放的次数*/
          animation-duration: 9s; /*动画所花费的时间*/
        }
        // div {
        //   width: 100%;
        //   height: 100vh;
        //   background-repeat: no-repeat !important;
        //   background-size: auto 100vh !important;
        // }
      }

      @keyframes scaleDraw {
        /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/

        0% {
          transform: scale(1.2); /*开始为原始大小*/
        }
        // 25% {
        //   transform: scale(1); /*放大1.1倍*/
        // }
        // 50% {
        //   transform: scale(1.2);
        // }
        100% {
          transform: scale(1);
        }
      }
    }
    .carditem {
      // width: 381px;
      cursor: pointer;
      padding: 0;

      .card-img-top {
        width: 100%;
        // height: 265px;
        height: auto;
      }
    }

    .card-body {
      position: relative;
      width: 100%;
      height: 260px;
      padding: 0px 0px;
      // padding: 30px 34px;
      box-sizing: border-box;
      font-family: PingFang-SC-ExtraLight;
      font-size: 18px;
      color: #000000;
      line-height: 35px;
      text-align: left;
      font-weight: 400;
      box-shadow: 0 12px 5px -5px #e2e7ef;
      // background-image: url("@/assets/bg.png");

      .bgimg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        // z-index: -1;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .texta {
        position: relative;
        z-index: 1000;
        padding: 30px 34px;
      }
    }
    //@at-root
    .box5 {
      font-family: Source Han Sans CN;
      font-size: 22px;
      color: #eeeeee;
      line-height: 38px;
    }
    .bglast {
      margin-top: 20px;
      height: 133px;
      opacity: 0.9;
      background: #0d1938;
    }
    .last {
      padding-top: 20px;
      text-align: left;
      font-family: Source Han Sans CN;
      font-size: 12px;
      color: #e8e8e8;
    }
    .last1 {
      margin-top: 15px;
    }
    .hovergroup {
      position: absolute;
      bottom: 0;
      left: 0;
      // left: 50%;
      // transform: translate(-50%);
      width: 100%;
      height: 78px;
      opacity: 0.88;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #121f42;
      border-radius: 1px;
      text-align: left;
    }
    .hovergroup1 {
      position: absolute;
      bottom: 0;
      left: 0;
      // left: 50%;
      // transform: translate(-50%);
      width: 100%;
      height: 78px;
      opacity: 0.88;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #121f42;
      border-radius: 1px;
      padding: 0 12px;
      text-align: left;
    }
    .hovertxt1 {
      font-family: Source Han Sans CN;
      font-size: 18px;
      color: #f7e2b4;
      letter-spacing: 0.64px;
      font-weight: 700;
    }
    .hovertxt2 {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #f7e2b4;
      letter-spacing: 0.64px;
    }
  }
  // Small devices (landscape phones, 576px and up)
  // 手机端
  @media (max-width: 576px) {
    .swiper-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%);
      z-index: 98;
    }
    ::v-deep .swiper-pagination-bullet {
      width: 18px !important;
      height: 8px;
      border-radius: 0;
      margin-right: 4px;
      display: inline-block;
    }
    ::v-deep .swiper-pagination-bullet-active {
      width: 18px !important;
      height: 8px;
      border-radius: 0;
      margin-right: 4px;
      display: inline-block;
      background: #023892;
    }

    .maptitle {
      font-family: Source Han Sans CN;
      font-size: 20px;
      color: #203670;
      font-weight: 700;
      letter-spacing: 0.9px;
    }
    .navtxt {
      font-size: 16px;
      color: #a52626;
      font-weight: 400;
    }
    .img3 {
      width: 100%;
      // height: 180px;
    }
    .img4 {
      margin-top: 12px;
      width: 100%;
      // height: 180px;
    }
    .img5 {
      margin-top: 10px;
      width: 100%;
      // height: 180px;
    }
    .img6 {
      margin-bottom: 10px;
      width: 100%;
      // height: 185px;
    }
    .imgmap {
      margin: 0 auto;
      height: 400px;
      width: 90%;
    }
    .ju {
      margin-top: 12px;
    }
    .teitem {
      // width: 398px;
      height: 30px;
      line-height: 30px;
      background: #f5f5f5;
      margin-right: 2px;
      color: #606060;
      font-size: 6px;
    }
    .bg1 {
      padding: 24px 0;
    }
    .bg2 {
      padding: 24px 0;
    }
    .bg4 {
      padding: 24px 0;
    }
    .bg3 {
      text-align: left;
      padding: 12px 0;
      background: #0d1a38;
    }
    .title3 {
      margin-bottom: 3px;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      font-weight: 600;
      color: #eeeeee;
    }
    .card-body {
      position: relative;
      padding: 10px 14px;
      box-sizing: border-box;
      font-family: PingFang-SC-ExtraLight;
      font-size: 12px;
      color: #000000;
      line-height: 25px;
      text-align: left;
      font-weight: 400;
      z-index: 100;

      // background-image: url("@/assets/bg.png");
      .texta {
        position: relative;
        z-index: 100;
      }
      .bgimg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        // z-index: -1;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .bg3title {
      position: relative;
      padding: 12px 0;
      text-align: left;
      font-family: Source Han Sans CN;
      font-size: 16px;
      color: #f7e2b4;
    }
    .bg3title:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 47px;
      left: 4%;
      width: 18%;
      height: 2px;
      background-color: #f7e2b4;
    }
    .line {
      margin-bottom: 12px;
      height: 1px;
      opacity: 0.21;
      border: 1px solid rgba(255, 248, 233, 1);
    }
    .carditem {
      padding: 0;
      margin-bottom: 12px;
      .card-img-top {
        width: 100%;
        // height: 250px;
        height: auto;
      }
    }
    .bannera {
      position: relative;
      width: 100%;
      // height: 200px;
      height: auto;
      overflow: hidden;
      .switem {
        width: 100%;
        height: auto;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          animation-name: scaleDraw; /*关键帧名称*/
          animation-timing-function: ease-in-out; /*动画的速度曲线*/
          animation-iteration-count: infinite; /*动画播放的次数*/
          animation-duration: 10s; /*动画所花费的时间*/
        }
      }

      @keyframes scaleDraw {
        /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/

        0% {
          transform: scale(1.2); /*开始为原始大小*/
        }
        // 25% {
        //   transform: scale(1); /*放大1.1倍*/
        // }
        // 50% {
        //   transform: scale(1.2);
        // }
        75% {
          transform: scale(1);
        }
      }
    }
    .btn {
      width: 100px;
      height: 24px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 1px;
      font-size: 8px;
      color: #f7e2b4;
      margin-top: 15px;
    }
    .imglogo {
      margin-top: 24px;
      width: 56px;
      height: 55px;
    }
    .box5 {
      font-family: Source Han Sans CN;
      font-size: 12px;
      color: #eeeeee;
      line-height: 18px;
    }
    .bglast {
      margin-top: 10px;
      height: 263px;
      opacity: 0.9;
      background: #0d1938;
    }
    .last {
      padding-top: 10px;
      text-align: left;
      font-family: Source Han Sans CN;
      font-size: 6px;
      color: #e8e8e8;
    }
    .last2 {
      margin-top: 4px;
    }
    .hovergroup {
      position: absolute;
      bottom: 0;
      left: 0;
      // left: 50%;
      // transform: translate(-50%);
      width: 100%;
      height: 48px;
      opacity: 0.88;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #121f42;
      border-radius: 1px;
    }
    .hovergroup1 {
      position: absolute;
      bottom: 0;
      left: 0;
      // left: 50%;
      // transform: translate(-50%);
      width: 100%;
      height: 48px;
      opacity: 0.88;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #121f42;
      box-sizing: border-box;
      padding: 0 12px;
      border-radius: 1px;
      text-align: left;
    }
    .hovertxt1 {
      font-family: Source Han Sans CN;
      font-size: 12px;
      color: #f7e2b4;
      letter-spacing: 0.64px;
      font-weight: 700;
    }
    .hovertxt2 {
      font-family: MicrosoftYaHei;
      font-size: 8px;
      color: #f7e2b4;
      letter-spacing: 0.64px;
    }
  }
}
</style>
